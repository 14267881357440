export const renderPrice = (price: number, discount?: number, old_price?: number|null) => {
  if (discount) {
    const discountedPrice = price - (price * discount) / 100;
    return (
        <>
          <span className="product-price product-price-discounted text-base md:text-lg font-medium md:font-semibold text-black">
            {(Math.round(discountedPrice * 100) / 100).toLocaleString('ru-RU')} c.
          </span>
          <span className="product-price product-price-original line-through text-base font-medium text-[#767676] ml-3">
            {(Math.round(price * 100) / 100).toLocaleString('ru-RU')} c.
          </span>
        </>
    )
  }

  return (
    ((old_price && ((Math.round(old_price * 100) / 100).toLocaleString("ru-RU") > (Math.round(price * 100) / 100).toLocaleString("ru-RU")))?
      <div className="flex">
        <span className="product-price text-base md:text-lg font-medium md:font-semibold text-black mr-5">
          {(Math.round(price * 100) / 100).toLocaleString('ru-RU')} c.
        </span>
        <span className="product-price line-through text-base md:text-lg font-medium md:font-semibold text-black" style={{ color: '#CCCCCC' }}>
          {(Math.round(old_price * 100) / 100).toLocaleString('ru-RU')} c.
        </span>
      </div>
      :
      <span className="product-price text-base md:text-lg font-medium md:font-semibold text-black">
        {(Math.round(price * 100) / 100).toLocaleString('ru-RU')} c.
      </span>
    )
  );
};
