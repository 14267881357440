// http://213.184.131.218:8087/api/orders/

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { itemsInOrder, orderReqFormType, orderType } from "../types/orderTypes";
import { AppDispatch, RootState } from "../store/store";
import { notification } from "antd";
import { clearProductsCartAC } from "../store/cartSlice";
import { orderFormDataIdAC, orderFormDataIsDeliveryAC } from "../store/ordersSlice";
//

// export const getOrdersAPI = async () => {
//   // try {
//     return await axios.get(
//       `${process.env.REACT_APP_API_URL}/orders?limit=10&page=${formData}&user=${userInfo?.id}`,
//       { headers: { Authorization: `Bearer ${accesstoken}` } }
//     );
//   // } catch (error) {
//   //   // return error;
//   // }
// };

export const getOrders = createAsyncThunk<
  any,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "orders/getallorders",
  async (formData: number, { rejectWithValue, getState }) => {
    try {
      const { accesstoken, userInfo } = getState().userSlice;
      const data: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/orders?limit=10&page=${formData}&user=${userInfo?.id}`,
        { headers: { Authorization: `Bearer ${accesstoken}` } }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("error occured");
    }
  }
);

interface createOrderFormData extends orderReqFormType {
  // is_send_sms: boolean;
  code: string;
  itemsInOrder?: itemsInOrder[];
  authorized: boolean;
}
export const createOrder = createAsyncThunk(
  "orders/create",
  async (
    {
      phone,
      code,
      itemsInOrder,
      authorized,
      ...createOrderData
    }: createOrderFormData,
    { rejectWithValue, getState, dispatch }
  ) => {
    if (authorized) {
      return await axios
        .post(`${process.env.REACT_APP_API_URL}/orders/`, {
          ...createOrderData,
          phone,
        })
        .then(async (res) => {
          if(res?.data?.id) {
            dispatch(orderFormDataIdAC(res.data.id))
            dispatch(orderFormDataIsDeliveryAC(res.data.is_delivery))
          }
          return await axios
            .post(
              `${process.env.REACT_APP_API_URL}/orderitems/`,
              itemsInOrder?.map((e) => ({
                ...e,
                order: res?.data?.id,
              }))
            )
            .then((r) => {
              dispatch(clearProductsCartAC());
              return "success";
            })
            .catch((err) => {
              if (err?.response?.status === 400) {
                throw new Error(
                  Object.keys(err.response.data)
                    ?.map((e) => err.response.data?.[e])
                    ?.join(",\n")
                );
              } else {
                throw new Error("err");
              }

              // return rejectWithValue("orderItems err");
            });
        })
        .catch((err) => {
          console.log("----->", err?.message);
          // return rejectWithValue("orders err");
          throw new Error(err?.message);
        });
    } else {
      return await axios
        .post(`${process.env.REACT_APP_API_URL}/checkcode/`, {
          code,
          phone,
        })
        
        .then(async (response) => {
          if (response.data?.is_valid_code) {
            return await axios
              .post(`${process.env.REACT_APP_API_URL}/orders/`, {
                ...createOrderData,
                phone,
              })
              .then(async (res) => {

                if(res?.data?.id) {
                  dispatch(orderFormDataIdAC(res.data.id))
                  dispatch(orderFormDataIsDeliveryAC(res.data.is_delivery))
                }
                
                const orderItems = await axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/orderitems/`,
                    itemsInOrder?.map((e) => ({
                      ...e,
                      order: res?.data?.id,
                    }))
                  )
                  .then((r) => {
                    dispatch(clearProductsCartAC());
                    return "success";
                  })
                  .catch((err) => {
                    if (err?.response?.status === 400) {
                      throw new Error(
                        Object.keys(err.response.data)
                          ?.map((e) => err.response.data?.[e])
                          ?.join(",\n")
                      );
                    } else {
                      throw new Error("err");
                    }

                    // return rejectWithValue("orderItems err");
                  });
              })
              .catch((err) => {
                console.log("----->", err?.message);
                if (err?.response?.status === 400) {
                  throw new Error(
                    Object.keys(err.response.data)
                      ?.map((e) => err.response.data?.[e])
                      ?.join(",\n")
                  );
                } else {
                  // return rejectWithValue("orders err");
                  throw new Error(err?.message);
                }
              });
            // return response.data;
          } else {
            notification.error({ message: "Не валидный код!" });
            return rejectWithValue("orders err");
          }
        })
        .catch((err) => {
          console.log("errr", err);
          return rejectWithValue(err?.message);
        });
    }
  }
);

export const getDeliveryData = createAsyncThunk(
  "orders/getDeliveryData",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/delivery`
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("error occured");
    }
  }
);

export const getOneOrders = createAsyncThunk<
  any,
  string | undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "orders/getoneorders",
  async (formData: string | undefined, { rejectWithValue, getState }) => {
    try {
      const { accesstoken } = getState().userSlice;
      const data: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/orders/${formData}/`,
        { headers: { Authorization: `Bearer ${accesstoken}` } }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("error occured");
    }
  }
);
