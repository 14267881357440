import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  deliveryDataType,
  orderDataResWithPaginationType,
  orderType,
} from "../types/orderTypes";
import {
  createOrder,
  getDeliveryData,
  getOneOrders,
  getOrders,
} from "../api/ordersApi";
import { notification } from "antd";

export interface ordersState {
  orders: orderType[] | null;
  ordersTotalCount: number | undefined;
  isFetchingOrders: boolean;
  errOrdders: string;

  orderCreated: orderType | null | boolean;
  isFetchingOrderCreating: boolean;
  errOrderCreate: string;

  orderFormData: orderType;

  fetchingDeliveryData: boolean;

  errDeliveryData: string;
  deliveryData: deliveryDataType;

  visibleModalCreaterOrder: boolean;
  visibleModalThanks: boolean;

  oneOrder: orderType | null;
  isFetchingOneOrder: boolean;
  errOneOrdder: string;
}

const initialState: ordersState = {
  errOrdders: "",
  errOrderCreate: "",
  isFetchingOrderCreating: false,
  isFetchingOrders: false,
  orderCreated: false,
  orders: null,
  ordersTotalCount: 0,
  orderFormData: {
    id: 0,
    add_date: "",
    comment: "",
    customer: "",
    is_delivery: false,
    payment: 0,
    phone: "",
    status: "",
    user: 0,
    address: "",
    delivery_price: 0,
    pick_up_time: "",
    sum_discount: 0,
  },
  fetchingDeliveryData: false,
  errDeliveryData: "",
  deliveryData: { delivery_price: 0, free_limit: 0, min_limit: 0 },
  visibleModalCreaterOrder: false,
  visibleModalThanks: false,
  errOneOrdder: "",
  isFetchingOneOrder: false,
  oneOrder: null,
};

export const ordersSlice = createSlice({
  name: "ordersSlice",
  initialState,
  reducers: {
    orderFormDataAC(state, action: PayloadAction<orderType>) {
      state.orderFormData = action.payload;
    },
    orderFormDataIdAC(state, action: PayloadAction<number>) {
      state.orderFormData.id = action.payload;  // Сохраняем только id 
    },
    orderFormDataIsDeliveryAC(state, action: PayloadAction<boolean>) {
      state.orderFormData.is_delivery = action.payload;
    },
    setVisibleModalAC(state, action: PayloadAction<boolean>) {
      state.visibleModalCreaterOrder = action.payload;
    },
    setVisibleModalThanksAC(state, action: PayloadAction<boolean>) {
      state.visibleModalThanks = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getOrders.pending, (state) => {
      state.errOrdders = "";
      state.isFetchingOrders = true;
      state.ordersTotalCount = 0;
    });
    builder.addCase(getOrders.rejected, (state, action: PayloadAction<any>) => {
      state.errOrdders = action.payload;
      state.isFetchingOrders = false;
      state.orders = null;
    });
    builder.addCase(
      getOrders.fulfilled,
      (state, action: PayloadAction<orderDataResWithPaginationType>) => {
        state.ordersTotalCount = action.payload.count;
        state.errOrdders = "";
        state.isFetchingOrders = false;
        state.orders = action.payload?.results;
      }
    );

    // createOrder
    builder.addCase(createOrder.pending, (state) => {
      state.errOrderCreate = "";
      state.isFetchingOrderCreating = true;
    });
    builder.addCase(
      createOrder.rejected,
      (state, action: PayloadAction<any>) => {
        state.errOrderCreate = action.payload;
        state.isFetchingOrderCreating = false;
        state.orderCreated = null;
        state.visibleModalCreaterOrder = false;
        notification.error({
          message: "Ошибка при заказе!",
          description: action.payload,
        });
      }
    );
    builder.addCase(
      createOrder.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.errOrderCreate = "";
        state.isFetchingOrderCreating = false;
        state.orderCreated = action.payload;
        state.visibleModalCreaterOrder = false;
        state.visibleModalThanks = true;

        console.log('Order created:', action);
        console.log('Updated state.orderCreated:', state.orderCreated);
      }
    );
    // getDeliveryData
    builder.addCase(getDeliveryData.pending, (state) => {
      state.errDeliveryData = "";
      state.fetchingDeliveryData = true;
    });
    builder.addCase(
      getDeliveryData.rejected,
      (state, action: PayloadAction<any>) => {
        state.errDeliveryData = action.payload;
        state.fetchingDeliveryData = false;
        state.deliveryData = { delivery_price: 0, free_limit: 0, min_limit: 0 };
      }
    );
    builder.addCase(
      getDeliveryData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.errDeliveryData = "";
        state.fetchingDeliveryData = false;
        state.deliveryData = action.payload;
      }
    );

    builder.addCase(getOneOrders.pending, (state) => {
      state.errOneOrdder = "";
      state.isFetchingOneOrder = true;
      // state.ordersTotalCount=0;
    });
    builder.addCase(
      getOneOrders.rejected,
      (state, action: PayloadAction<any>) => {
        state.errOneOrdder = action.payload;
        state.isFetchingOneOrder = false;
        state.oneOrder = null;
      }
    );
    builder.addCase(
      getOneOrders.fulfilled,
      (state, action: PayloadAction<orderType>) => {
        // state.ordersTotalCount=action.payload.count;
        state.errOneOrdder = "";
        state.isFetchingOneOrder = false;
        state.oneOrder = action.payload;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const { orderFormDataAC, orderFormDataIdAC, orderFormDataIsDeliveryAC, setVisibleModalAC, setVisibleModalThanksAC } =
  ordersSlice.actions;

export default ordersSlice.reducer;
